import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductIcon from "../components/productIcon"

const IndexPage = () => {

    return < Layout >
        <section class="bg-white">
            <div
                class="mx-auto max-w-screen-xl px-4 py-32 my-16 lg:flex lg:h-max lg:items-center"
            >
                <div class="mx-auto max-w-2xl text-center">
                    <h1 class="text-3xl font-extrabold sm:text-5xl">
                        Our Team
                    </h1>

                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        At the moment, we do not have pictures and profiles for each member. And so, we do not feel it would be fair to list only half of our team.
                    </p>
                    <p class="mt-4 sm:text-xl sm:leading-relaxed text-primary">
                        (instead here's a picture of a panda)
                    </p>
                    <img class="rounded my-16" src="https://images.unsplash.com/photo-1527118732049-c88155f2107c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGFuZGF8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60" alt="Really cute picture of a panda that we found on Unsplash" />
                </div>
            </div>
        </section>
    </Layout >
}

export const Head = () => <Seo title="Our Team" />

export default IndexPage
